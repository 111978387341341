import { Text } from '@chakra-ui/layout';
import { useBreakpointValue } from '@chakra-ui/media-query';
import { Box, Grid, GridItem } from '@chakra-ui/react';
import BaseBackground from '@components/Background/BaseBackground';
import Container from '@components/LayoutStructure/Container';
import { filterNonReactAttributes } from '@utils/filterNonReactAttributes';
import { getColor } from '@utils/getColor';
import { MagnoliaImage } from '~/types/Magnolia';
import { OverlayColors } from '~/types/OverlayColors';

export interface ImageTestimonialProps {
  quote: {
    text?: string;
    color?: { color: string; range: string };
  };
  author: {
    name?: string;
    description?: string;
    color?: { color: string; range: string };
  };
  textAlignment?: 'left' | 'center' | 'right';
  backgroundImage: {
    image?: MagnoliaImage;
    imageUrl?: string;
    bgImageOverlay: OverlayColors;
    bgOverlayOpacity?: string;
    bgOverlayGradient?: 'solid' | 'left' | 'right';
    bgImagePosition?: string;
    bgBorderRadius?: string;
    field?: 'bgImageForm' | 'bgExternalImageForm';
  };
  altImageProperties: {
    bgImageOverlay: OverlayColors;
    bgOverlayOpacity?: string;
    bgOverlayGradient?: 'solid' | 'left' | 'right';
    bgImagePosition?: string;
    bgBorderRadius?: string;
    viewports: ('Mobile' | 'Tablet')[];
  };
}

const ImageTestimonial: React.FC<ImageTestimonialProps> = ({
  quote,
  author,
  textAlignment = 'left',
  backgroundImage,
  altImageProperties,
}) => {
  const { field, image, imageUrl, ...defaultImageProps } = backgroundImage;
  const { viewports, ...altProps } = altImageProperties;

  const altImageProps = useBreakpointValue({
    base: viewports.includes('Mobile') ? altProps : {},
    md: viewports.includes('Tablet') ? altProps : {},
    lg: {},
  });
  const useAltImageProps =
    altImageProps && Object.keys(altImageProps).length > 0;

  const imageProps =
    filterNonReactAttributes(
      useAltImageProps ? altImageProps : defaultImageProps
    ) ?? {};

  return (
    <BaseBackground
      type={field}
      image={image}
      imageUrl={imageUrl}
      {...imageProps}
    >
      <Container
        px={8}
        pb={{ base: '70px', md: '165px' }}
        pt={{ base: '300px', md: '165px' }}
        mb={0}
        as="section"
      >
        <Grid
          templateColumns={{
            base: '1fr',
            md: 'repeat(2, 1fr)',
            lg: 'repeat(3, 1fr)',
          }}
          lineHeight={'36px'}
          mt={8}
        >
          <GridItem
            colStart={{ base: 1, md: 2, lg: 3 }}
            colEnd={{ base: 1, md: 2, lg: 3 }}
            textAlign={textAlignment}
          >
            <Text
              mb={8}
              fontSize="3xl"
              fontWeight={700}
              color={getColor(quote?.color?.color, quote?.color?.range)}
            >
              {quote.text}
            </Text>

            <Box
              color={getColor(author?.color?.color, author?.color?.range)}
              lineHeight="24px"
            >
              <Text fontSize="xl" fontWeight={700}>
                {author.name}
              </Text>
              <Text fontSize="xl" fontWeight={400}>
                {author.description}
              </Text>
            </Box>
          </GridItem>
        </Grid>
      </Container>
    </BaseBackground>
  );
};

export default ImageTestimonial;
